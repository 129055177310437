export enum AvatarColor {
    white = "white",
    blue = "blue",
    purple = "purple",
    red = "red",
    orange = "orange",
    yellow = "yellow",
    green = "green",
}

export enum AvatarSize {
    xxl = "xxl",
    xl = "xl",
    lg = "lg",
    md = "md",
    sm = "sm",
    xs = "xs",
    xxs = "xxs",
    tiny = "tiny",
    xtiny = "xtiny",
}

export interface AvatarProps {
    initials?: string;
    firstname?: string;
    lastname?: string;
    color?: keyof typeof AvatarColor;
    size?: keyof typeof AvatarSize;
    imageUrl?: string;
    displayName?: boolean;
}