import { TranslateResult } from "vue-i18n";

export interface TooltipProps {
    mode?: "click" | "hover";
    nostyle?: boolean;
    open?: boolean;
    onChange?: () => void;
    expand?: "left" | "right";
    width?: string;
    maxHeight?: string;
}

export interface TooltipItemProps {
    label: string | TranslateResult;
    contentRight?: { icon?: string; iconColor: TooltipItemColors; text?: string; textColor?: TooltipItemColors };
    iconLeft?: string;
    iconLeftColor?: TooltipItemColors;
    checked?: boolean;
    checkbox?: boolean;
    loading?: boolean;
}

export interface TooltipTitleProps {
    icon: string;
    label: string | TranslateResult;
}

export interface TooltipGeneratorProps {
    actions: {
        type: "title" | "separator" | "item";
        item: any;
        title?: TooltipTitleProps;
    }[];
    relatedData?: any;
}

export enum TooltipItemColors {
    white = "white",
    blue = "blue",
    purple = "purple",
    red = "red",
    orange = "orange",
    yellow = "yellow",
    green = "green",
}
